import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, CardHeader } from "@material-ui/core";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TreatmentForm from "../Treatments/TreatmentForm";
import axiosWithAuth from "../Auth/AxiosWithAuth";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 350,
    margin: 20,
    height: "",
  },
  content: {
    display: "flexbox",
    justifyContent: "center",
    marginTop: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.longest,
    }),
  },
  expandOpen: {
    // transform: 'rotate(180deg)',
  },
  label: {
    fontWeight: 700,
    marginRight: "10px",
  },
  title: {
    fontWeight: 700,
    padding: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    margin: "auto, 10px",
  },
}));

const HeartCheckbox = withStyles({
  root: {
    color: "#3d8f6d",
    "&$checked": {
      color: "#3d8f6d",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const FavoriteStrain = (props) => {
  console.log(props);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
    checkedH: true,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        action={
          <FormControlLabel
            control={
              <HeartCheckbox
                onClick={(favorite_id) => {
                  axiosWithAuth()
                    .delete(
                      `https://medizen-api.herokuapp.com/api/favorites/strains/${props.favorite_id}`,
                      {
                        favorite_id: !favorite_id,
                      }
                    )
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                icon={
                  <Favorite
                    checked={state.checkedH}
                    onChange={handleChange("checkedH")}
                  />
                }
                checkedIcon={<FavoriteBorder />}
              />
            }
          ></FormControlLabel>
        }
        title={props.title}
      ></CardHeader>
      {
        <CardContent>
          <Typography className={classes.row}>
            <h3 className={classes.label}>Type:</h3> {props.type}
          </Typography>
          <Typography className={classes.row}>
            {" "}
            <h3 className={classes.label}> Effects: </h3>
            {props.effects.map((element) => {
              return `${element} `;
            })}
          </Typography>
          <Typography className={classes.row} paragraph>
            <h3 className={classes.label}>Flavor: </h3>
            {props.flavor.map((element) => {
              return `${element} `;
            })}
          </Typography>
        </CardContent>
      }

      <CardActions disableSpacing>
        <Button
          color="primary"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          View Details
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{props.description}</Typography>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
          >
            <span className="Filter">Start a Treatment Plan</span>
          </Button>
          <div className="TreatForm">
            <Menu
              className="container"
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <TreatmentForm
                onClick={handleClose}
                favorite_id={props.favorite_id}
                title={props.title}
                type={props.type}
                effects={props.effects}
                flavors={props.flavors}
                description={props.description}
              ></TreatmentForm>
            </Menu>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default connect()(FavoriteStrain);
