import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Treatment from "./Treatment";
import FavoriteStrain from "../Strain/Favorite";
import Card from "@material-ui/core/Card";
import SimpleMenu from "./AddTreatButton";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, CardHeader } from "@material-ui/core";

const axiosWithAuth = () => {
  return axios.create({
    headers: {
      authorization: localStorage.getItem("token"),
    },
  });
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: 450,
    margin: 20,
  },
}));

class Treatments extends React.Component {
  state = {
    treatments: [
      {
        id: "",
        user_id: "",
        strain: "",
        method: "",
        dosage: "",
        schedule: "",
        symptoms: "",
      },
    ],
    favorites: [],
  };

  componentDidMount() {
    this.getData();
    if (!localStorage.getItem("token")) {
    } else {
    }
  }

  getData = () => {
    axiosWithAuth()
      .get("https://medizen-api.herokuapp.com/api/treatments")
      .then((response) => {
        console.log(response);
        this.setState({ treatments: response.data });
        console.log(this.state.treatments);
      });
    axiosWithAuth()
      .get("https://medizen-api.herokuapp.com/api/favorites/strains")
      .then((response) => {
        console.log(response.data);
        this.setState({ favorites: response.data });
        console.log(this.state.favorites);
      });
    console.log(this.state.treatments.title == this.state.favorites.strain);
  };

  // Plans = (state) => {
  //   const Ttmts = this.state.treatments.strain == this.state.favorites.title;
  //   console.log("Ttmts");
  // };
  // Plans()

  render() {
    return (
      <>
        <div className="container">
          <h1>Treatment Plans</h1>
          <SimpleMenu />
        </div>
        <div>
          <div className="wrap">
            <Card>
              <div className="wrap">
                {this.state.treatments.map((treatment) => (
                  <Treatment
                    id={treatment.id}
                    strain={treatment.strain}
                    method={treatment.method}
                    dosage={treatment.dosage}
                    schedule={treatment.schedule}
                    symptoms={treatment.symptoms}
                    description={this.state.favorites.favorite_id}
                  ></Treatment>
                ))}
              </div>
            </Card>
            {/* {this.state.favorites.map((favorite) => (
              <FavoriteStrain
                favorite_id={favorite.favorite_id}
                key={favorite.strain_id}
                title={favorite.strain}
                // type={strain.type}
                effects={favorite.effects}
                flavor={favorite.flavors}
                // description={strain.description}
                // is_favorite={true}
              ></FavoriteStrain>
            ))} */}
            {/* <Card className="wrap">
              <div>
                {this.state.favorites.map((favorite) => (
                  <div>
                    <FavoriteStrain
                      s
                      title={favorite.strain}
                      flavor={favorite.flavors}
                      effects={favorite.effects}
                      type={favorite.type}
                    ></FavoriteStrain>
                  </div>
                ))}
              </div>{" "}
            </Card> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    strains: state,
    favorites: state,
  };
};
export default connect(mapStateToProps)(Treatments);
