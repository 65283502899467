import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Types from "./Types";
import Flavors from "./Tastes";
import Effects from "./Effects";

const ExpansionPanel = withStyles({
  root: {
    maxWidth: "100%",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: "none",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .00)",

    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {},
  },
  expanded: {
    maxWidth: "100%",
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(MuiExpansionPanelDetails);

export default function CustomizedExpansionPanels() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <ExpansionPanel
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div>
            <Typography>
              <Button color="primary">
                <ExpandMoreIcon />
                <span className="Filter">Filter Types</span>
              </Button>
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Types />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpansionPanelSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <div>
            <Typography>
              <Button color="primary">
                <ExpandMoreIcon />
                <span className="Filter">Filter Effects</span>
              </Button>
            </Typography>
          </div>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Effects />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <ExpansionPanelSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <div>
            <Typography>
              <Button color="primary">
                <ExpandMoreIcon />
                <span className="Filter">Filter Flavors</span>
              </Button>
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Flavors />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
