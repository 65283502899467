import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import banner from "../MarketingPage/images/banner.jpeg";

const NewLogin = ({ values, errors, touched, status }) => {
  const [userState, setUserState] = useState([]);

  useEffect(() => {
    status && setUserState((user) => [...user, status]);
  }, [status]);

  return (
    <div className="Form">
      {status ? (
        <Redirect to={{ pathname: "/strains" }} />
      ) : (
        <div>
          <Form>
            <h2 className="h2">Log in</h2>
            <div>
              <label>Email</label>
              <Field className="Input" type="email" name="email" />
              {touched.email && errors.email && <p>{errors.email}</p>}
              <label>Password</label>
              <Field
                className="Input"
                type="password"
                name="password"
                autoComplete="off"
              />
              {touched.password && errors.password && <p>{errors.password}</p>}

              <button className="PrimaryBtn" type="submit">
                Continue
              </button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

const FormikNewLogin = withFormik({
  mapPropsToValues({ email, password }) {
    return {
      email: email || "",
      password: password || "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string()
      .required("No Password Provided.")
      .min(8, "Password too short. 8 characters minimum."),
  }),
  handleSubmit(values, { setStatus, resetForm }) {
    axios
      .post("https://medizen-api.herokuapp.com/api/auth/login", values)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setStatus(response.data);
        let isLoggedIn = true;
        this.props.history.push("/strains");
      })
      .catch((err) => console.log(err.response));
    resetForm();
  },
})(NewLogin);

export default FormikNewLogin;
