import React, { Component } from "react";
import { connect } from "react-redux";
import Strain from "./Strain";
import axios from "axios";
import FilterButton from "./FilterButton";
import ExpansionPanel from "../Filter/ExpansionPanel";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CustomizedExpansionPanels from "../Filter/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Triangles from "../images/Triangles.mp4";
import Loader from "react-loader-spinner";

const axiosWithAuth = () => {
  return axios.create({
    headers: {
      authorization: localStorage.getItem("token"),
    },
  });
};

class Strains extends React.Component {
  state = {
    strains: [],
  };

  componentDidMount() {
    this.getData();
    if (!localStorage.getItem("token")) {
      console.error("Please Login!!!");
    } else {
      console.info("We are logged in");
    }
  }

  getData = () => {
    axiosWithAuth()
      .get("https://medizen-api.herokuapp.com/api/strains")
      .then((response) => {
        this.setState({ strains: response.data });
      });
  };

  render() {
    return (
      <>
        <div className="">
          <FilterButton />
          <div className="container">
            <h1 className="Heading">All Strains</h1>
          </div>
          {this.state.strains == 0 ? (
            <div className="container">
              <Loader
                className="loader"
                type="Grid"
                color="#3d8f6d"
                height={100}
                width={200}
              ></Loader>
              <h4>Fetching hundreds of strains...</h4>
            </div>
          ) : (
            <div className="wrap">
              {" "}
              {this.state.strains.map((strain) => (
                <Strain
                  key={strain.strain_id}
                  strain_id={strain.strain_id}
                  title={strain.strain}
                  type={strain.type}
                  effects={strain.effects}
                  flavor={strain.flavors}
                  description={strain.description}
                ></Strain>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    strains: state,
  };
};
export default connect(mapStateToProps)(Strains);
