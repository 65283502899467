import React, { Component } from "react";
import { connect } from "react-redux";
import FavoriteStrain from "./Favorite";
import axios from "axios";
import CustomizedExpansionPanels from "../Filter/ExpansionPanel";

const axiosWithAuth = () => {
  return axios.create({
    headers: {
      authorization: localStorage.getItem("token"),
    },
  });
};

class Strains extends React.Component {
  state = {
    strains: [],
  };

  componentDidMount() {
    this.getData();
    if (!localStorage.getItem("token")) {
      console.error("Please Login!!!");
    } else {
      console.info("We are logged in");
    }
  }

  getData = () => {
    axiosWithAuth()
      .get("https://medizen-api.herokuapp.com/api/favorites/strains")
      .then((response) => {
        this.setState({ strains: response.data });
        console.log(response);
      });
  };
  render() {
    return (
      <>
        <div className="container">
          <h1 className="Heading">Favorites</h1>
        </div>
        {this.state.strains == 0 ? (
          <div className="container">
            <h3>
              You don't have favorites yet. Click the heart icon in Discovery
              strains to add strains to your favorites list. Once your favorites
              are selected, you can add treatment plans to your favorited
              strains.
            </h3>{" "}
          </div>
        ) : (
          <div className="container">
            <h3>
              View details to add treatment plans to your favorited strains.
            </h3>
          </div>
        )}
        <div className="wrap">
          {this.state.strains.map((strain) => (
            <FavoriteStrain
              favorite_id={strain.favorite_id}
              key={strain.strain_id}
              title={strain.strain}
              type={strain.type}
              effects={strain.effects}
              flavor={strain.flavors}
              description={strain.description}
              is_favorite={true}
            ></FavoriteStrain>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    strains: state,
  };
};
export default connect(mapStateToProps)(Strains);
