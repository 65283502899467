import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import axiosWithAuth from "../Auth/AxiosWithAuth";
import { makeStyles } from "@material-ui/core/styles";
import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

class TreatmentForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const strain = this.getStrain.value;
    const method = this.getMethod.value;
    const dosage = this.getDosage.value;
    const schedule = this.getSchedule.value;
    const symptoms = this.getSymptoms.value;
    const data = {
      id: new Date(),
      strain,
      method,
      dosage,
      schedule,
      symptoms,
      editing: false,
    };
    this.props.dispatch({ type: "ADD_TREATMENT", data });

    this.getStrain.value = "";
    this.getMethod.value = "";
    this.getDosage.value = "";
    this.getSchedule.value = "";
    this.getSymptoms.value = "";
  };

  render() {
    return (
      <>
        <div>
          <div className="FormCont">
            <Card className="card">
              <CardHeader
                title={<h3 className="label">{this.props.title}</h3>}
              ></CardHeader>{" "}
              <p className="label"> Treatment Plan</p>
              {
                <CardContent>
                  <Typography paragraph>{this.props.description}</Typography>
                  <Typography className="column">
                    <p className="label">{this.props.type}</p>

                    {/* <p className="label">
                      {this.props.effects.map((element) => {
                        return `${element} `;
                      })}
                    </p> */}
                  </Typography>
                  <Typography className="row" paragraph>
                    {/* <p className="label">
                      {this.props.flavor.map((element) => {
                        return `${element} `;
                      })}
                    </p> */}
                  </Typography>
                </CardContent>
              }
              <div>
                <form className="Form" onSubmit={this.handleSubmit}>
                  <h3>Symptoms</h3>
                  <input
                    className="Input"
                    required
                    type="text"
                    ref={(input) => (this.getSymptoms = input)}
                    cols="28"
                  />
                  <br />
                  <br />
                  <h3>Strain</h3>
                  <input
                    className="Input"
                    required
                    type="text"
                    ref={(input) => (this.getStrain = input)}
                    placeholder={this.props.title}
                  />
                  <br />
                  <br />
                  <h3>Intake Method</h3>
                  <input
                    className="Input"
                    required
                    type="text"
                    ref={(input) => (this.getMethod = input)}
                    placeholder=""
                  />
                  <br />
                  <br />
                  <h3>Dosage</h3>
                  <input
                    className="Input"
                    required
                    type="text"
                    ref={(input) => (this.getDosage = input)}
                    placeholder=""
                  />
                  <br />
                  <br />
                  <h3>Schedule</h3>
                  <input
                    className="Input"
                    required
                    type="text"
                    ref={(input) => (this.getSchedule = input)}
                    placeholder=""
                  />
                  <br />
                  <br />
                  <button
                    className="PrimaryBtn"
                    onClick={() => {
                      axiosWithAuth()
                        .post(
                          "https://medizen-api.herokuapp.com/api/treatments",
                          {
                            strain: this.getStrain.value,
                            method: this.getMethod.value,
                            dosage: this.getDosage.value,
                            schedule: this.getSchedule.value,
                            symptoms: this.getSymptoms.value,
                          }
                        )
                        .then((response) => {
                          console.log(response);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    Add Treatment
                  </button>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default connect()(TreatmentForm);
