import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  Paper,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";

function Footer() {
  return (
    <div>
      <footer></footer>
    </div>
  );
}

export default Footer;
